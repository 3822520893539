<template>
    <div class="">
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
        <div class="card-table-body ifon-add-title-block">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="caz-blocks-sarcho-title">
                <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.sale_report") }} </div>
              </div>
            </el-col>
            <el-col :span="12" class="flex-style text-right filter_home">
                <el-radio-group v-model="radioPeriod" size="mini" @change="updatePeriod"
                    :disabled="(filterForm.from_date || filterForm.to_date) ? true : false">
                    <el-radio-button value="weekly" label="weekly">{{ $t('message.weekly') }}</el-radio-button>
                    <el-radio-button value="monthly" label="monthly">{{ $t('message.monthly') }}</el-radio-button>
                    <el-radio-button value="yearly" label="yearly">{{ $t('message.yearly') }}</el-radio-button>
                </el-radio-group>
            </el-col>
          </el-row>
        </div>
  
        <div class="card-table-header table-crm-smart">
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-card class="mm_cards" shadow="always">
                        <p class="font-bold">{{ $t('message.sales') }}</p>
                        <p>{{ getSalesTotal | formatMoney(2) }}</p>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card class="mm_cards" shadow="always">
                        <p class="font-bold">{{ $t('message.profit') }}</p>
                        <p>{{ getProfitTotal | formatMoney(2) }}</p>
                        
                    </el-card>
                </el-col>
                <!-- <el-col :span="8">
                    <el-card class="mm_cards" shadow="always">
                        <p class="font-bold">{{ $t('message.avarage_price') }}</p>
                        <p>234324.458</p>
                    </el-card>
                </el-col> -->
                <el-col :span="8">
                    <el-card class="mm_cards" shadow="always">
                        <p class="font-bold">{{ $t('message.profitability') }}</p>
                        <p>{{ getProfitPercentage }} %</p>
                    </el-card>
                </el-col>
            </el-row>

            <el-row :gutter="20" class="mt-5">
                <el-col :span="24">
                    <div class="w-100 mm-diagram-height ">
                        <SaleByPeriod 
                            :mode="mode" 
                            @vmodel="vModel('SaleByPeriod', $event)"
                            :allPeriodChange="radioPeriod"
                            :filterForm="filterForm"
                        >
                        </SaleByPeriod>
                    </div>
                </el-col>
            </el-row>

            <el-row :gutter="20" class="mt-5">
                <el-col :span="12">
                    <div class="w-100 mm-diagram-height ">
                        <DealsByCountry 
                            :mode="mode" 
                            @vmodel="vModel('DealsByCountry', $event)"
                            :allPeriodChange="radioPeriod"
                            :filterForm="filterForm"
                        >
                        </DealsByCountry>
                    </div>
                </el-col>

                <el-col :span="12">
                    <div class="w-100 mm-diagram-height">
                        <Categories 
                            :mode="mode" 
                            @vmodel="vModel('Categories', $event)"
                            :allPeriodChange="radioPeriod"
                            :filterForm="filterForm"
                        >
                        </Categories>
                    </div>
                </el-col>
            </el-row>    
            <el-row :gutter="20" class="mt-5">
                <el-col :span="12">
                    <div class="w-100 mm-diagram-height">
                        <DealsByManager 
                            :mode="mode" 
                            :allPeriodChange="radioPeriod"
                            :filterForm="filterForm"
                        >
                        </DealsByManager>
                    </div>
                </el-col>
            </el-row>    
            
        </div>
      </div>
    </div>
  </template>
  
<script>
    import { mapGetters, mapActions } from "vuex";
    import DealsByCountry from '@/components/dashboard/DealsByCountry'
    import Categories from '@/components/dashboard/Categories'
    import DealsByManager from '@/components/dashboard/DealsByManager'
    import SaleByPeriod from '@/components/dashboard/SaleByPeriod'
    import _ from 'lodash';
    
    export default {
        name: "sale_report",
        components: {
            DealsByCountry,
            Categories,
            DealsByManager,
            SaleByPeriod
        },
    
        data() {
            return {
                radioPeriod: 'weekly',
                filterForm: {
                    from_date: '', 
                    to_date: '', 
                }
            };
        },
        created() {
            this.fetchData();
        },

        watch: {
            saleByPeriod: {
                handler: async function(newVal, oldVal) {
                    console.log(newVal, 'newValnewValnewVal');
                },
                deep: true,
                immediate: true
            },
        },
    
        computed: {
            ...mapGetters({
                mode: "MODE",
                saleByPeriod: "home/saleByPeriod"
            }),
            actions: function () {
                return ["edit", "delete"];
            },
            getSalesTotal() {
                return (this.saleByPeriod && this.saleByPeriod.money_amounts) ? this.saleByPeriod.money_amounts.reduce((a, b) => parseFloat(a || 0) +  parseFloat(b || 0), 0) : 0;
            },
            getProfitTotal() {
                return (this.saleByPeriod && this.saleByPeriod.money_amounts) ? this.saleByPeriod.profit_money_amounts.reduce((a, b) => parseFloat(a || 0) +  parseFloat(b || 0), 0) : 0;
            },
            // getARPU() {
            //     return 0;
            // },
            getProfitPercentage() {
                let percentage = this.getSalesTotal > 0 ? (this.getProfitTotal * 100  /this.getSalesTotal) : 100;
                return _.round(percentage, 2);
                
            }
        },
        methods: {
            ...mapActions({
                updateCountryDeals: "home/countryDeals",
                productSellStatisticsData: "home/productSellStatistics",
            }),

            vModel(comp, evt) {
                if(comp == 'DealsByCountry') {
                    this.updateCountryDeals({ period: evt, filter: this.filterForm });
                }else if(comp == 'Categories') {
                    this.productSellStatisticsData({ period: evt, filter: this.filterForm });
                }else if(comp == 'DealsByManager') {
                    this.productSellStatisticsData({ period: evt, filter: this.filterForm });
                }
            },

            updatePeriod(val) {
                this.fetchData();
            },

            fetchData() {
                this.updateCountryDeals({ period: this.radioPeriod, filter: this.filterForm });
                this.productSellStatisticsData({ period: this.radioPeriod, filter: this.filterForm });
            }
        
        },
        beforeRouteLeave (to, from, next) {
            this.$store.commit('reasons/EMPTY_LIST');
            next()
        },
    };
</script>
  
<style>

.card__tablenight .mm_cards {
    background-color: #080c24;
}

.mm-diagram-height {
    height: 500px;
}

</style>
  